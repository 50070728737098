const api = {
  get(path, data = {}, headers = {}) {
    return this.call(path, data, headers, 'get');
  },

  post(path, data = {}, headers = {}) {
    return this.call(path, data, headers, 'post');
  },

  async call(path, data = {}, headers = {}, method = 'get') {
    const loginToken = localStorage.loginToken || '';

    // Default headers
    const defaultHeaders = new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${loginToken}`,
    });

    Object.keys(headers).forEach(key => {
      defaultHeaders.set(key, headers[key]);
    });

    const args = {
      headers: defaultHeaders,
      method,
      credentials: 'same-origin',
    };

    if (method !== 'get') args.body = JSON.stringify(data);

    const response = await fetch(process.env.BACKEND_URL + path, args);

    return new Promise(resolve => {
      response.json().then(result => {
        resolve({
          data: result.code === -1 ? result.data : result,
          code: result.code || 1,
          status: response.status || 200,
          errors: result.errors || null,
        });
      });
    });
  },
};

export default api;
